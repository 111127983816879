import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ComplaintIdValidator } from '@shared/validators/complaint-id-validator';
import { AuthService } from 'app/auth/auth.service';
import { IComplaint } from 'app/models/complaints/complaint';
import { IComplaintDialogData } from 'app/models/complaints/complaint-dialog-data';
import { IComplaintParent } from 'app/models/complaints/complaint-parent';
import { ComplaintService } from 'app/services/complaints/complaint.service';
import { SharedService } from 'app/services/core/shared.service';

export const DateFormats = {
  parse: {
    dateInput: ['MM/DD/YYYY']
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};

@Component({
  selector: 'app-complaint',
  templateUrl: './complaint.component.html',
  styleUrls: ['./complaint.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: DateFormats }]
})
export class ComplaintDialogComponent implements OnInit {
  public complaints: IComplaint[] = [];
  complaintForm = new UntypedFormGroup({
    status: new UntypedFormControl(""),
    dateCreated: new UntypedFormControl(""),
    noticeStatus: new UntypedFormControl(""),
    name: new UntypedFormControl("", [Validators.required]),
    contactEmail: new UntypedFormControl("", [Validators.required]),
    contactNumber: new UntypedFormControl("", [Validators.required, Validators.pattern(/^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4}).*$/)]),
    preferredContact: new UntypedFormControl("email", [Validators.required]),
    identityConcern: new UntypedFormControl("", [Validators.required]),
    parentLicenseName: new UntypedFormControl(""),
    parentId: new UntypedFormControl("", {
      asyncValidators: [this.complaintIdValidator.validate.bind(this.complaintIdValidator)],
      updateOn: 'blur'
    }),
    complaintText: new UntypedFormControl("", [Validators.required]),
    previousReporting: new UntypedFormControl("", [Validators.required]),
    signature: new UntypedFormControl("", [Validators.required])
  });

  constructor(public dialogRef: MatDialogRef<ComplaintDialogComponent>,
              private complaintService: ComplaintService,
              public sharedService: SharedService,
              private complaintIdValidator: ComplaintIdValidator,
              public authService: AuthService,
              @Inject(MAT_DIALOG_DATA) public data: IComplaintDialogData) { }

  ngOnInit(): void {
    this.complaintForm.patchValue({
      name: this.data.complaint.name,
      status: this.data.complaint.status,
      noticeStatus: this.data.complaint.noticeStatus,
      contactEmail: this.data.complaint.contactEmail,
      contactNumber: this.data.complaint.contactNumber,
      preferredContact: this.data.complaint.preferredContact,
      identityConcern: this.data.complaint.identityConcern,
      parentLicenseName: this.data.complaint.parentLicenseName,
      parentId: this.data.complaint.parentId,
      complaintText: this.data.complaint.complaintText,
      previousReporting: this.data.complaint.previousReporting,
      signature: this.data.complaint.signature
    });

    if(this.data.complaint.dateCreated != null && this.data.complaint.dateCreated.length > 0)
      this.complaintForm.patchValue({
        dateCreated: new Date(this.data.complaint.dateCreated)
      });

    if(this.data.disableParentSelection) {
      this.complaintForm.get('parentId').disable();
    }
    this.complaintForm.get('parentLicenseName').disable();
  }

    isParentIdEmptyOrSpaces() {
    const parentIdValue = this.complaintForm.get('parentId').value;
    return !parentIdValue || /^\s*$/.test(parentIdValue);
  }

  submitComplaint(): void {
    let form = this.complaintForm.value;
    let parentIdToSave = form.parentId != null && form.parentId != undefined ? form.parentId : this.data.complaint.parentId;

    let complaint: IComplaint = {
      id: this.data.complaint.id,
      entityType: this.data.type,
      status: form.status,
      dateCreated: form.dateCreated,
      noticeStatus: form.noticeStatus,
      name: form.name,
      contactEmail: form.contactEmail,
      contactNumber: form.contactNumber,
      preferredContact: form.preferredContact,
      identityConcern: form.identityConcern,
      parentId: parentIdToSave,
      parentName: this.data.complaint.parentName,
      complaintText: form.complaintText,
      previousReporting: form.previousReporting,
      signature: form.signature,
      parentLicenseName: this.data.complaint.parentLicenseName,
      assignedTo: '',
      caseId: this.data.caseId
    }
    this.complaintService.saveComplaint(complaint).subscribe(
      response => this.dialogRef.close(response),
      error => {
        console.log('error', error);
        this.dialogRef.close(false);
      }
    );
  }

  cancel(): void {
    this.dialogRef.close();
  }

}
