import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { IPayment } from '../models/payment';

@Injectable({providedIn: 'root'})
export class PaymentService {
  public applicationFeeType = "ApplicationFee";
  public licenseFeeType = "LicenseFee";
  public reducedLicenseFeeType = "ReducedLicenseFee";
  public changeRequestFeeType = "ChangeRequestFee"
  public changeLocationFeeType = "ChangeLocationFee";
  public civilPenaltyFeeType = "CivilPenaltyFee";

  public trainingProgramPaymentOption = "TrainingProgram";
  public facilitatorPaymentOption = "FacilitatorLicense";
  public ServiceCenterPaymentOption = "ServiceCenterLicense";
  public WorkerPermitPaymentOption = "WorkerPermitLicense";
  public ManufacturerPaymentOption = "ManufacturerLicense";
  public TestingLabPaymentOption = "TestingLabLicense";
  public paymentOptions = [
    {
      Text: "Training Program",
      EntityType: this.trainingProgramPaymentOption,
      FeeType: this.applicationFeeType,
      Amount: "500.00",
    },
    {
      Text: "Facilitator Application Fee",
      EntityType: this.facilitatorPaymentOption,
      FeeType: this.applicationFeeType,
      Amount: "150.00"
    },
    {
      Text: "Facilitator License Fee",
      EntityType: this.facilitatorPaymentOption,
      FeeType: this.licenseFeeType,
      Amount: "2000.00"
    },
    {
      Text: "Facilitator Reduced License Fee",
      EntityType: this.facilitatorPaymentOption,
      FeeType: this.reducedLicenseFeeType,
      Amount: "1000.00"
    },
    {
      Text: "Service Center Application Fee",
      EntityType: this.ServiceCenterPaymentOption,
      FeeType: this.applicationFeeType,
      Amount: "500.00"
    },
    {
      Text: "Service Center License Fee",
      EntityType: this.ServiceCenterPaymentOption,
      FeeType: this.licenseFeeType,
      Amount: "10000.00"
    },
    {
      Text: "Service Center Reduced License Fee",
      EntityType: this.ServiceCenterPaymentOption,
      FeeType: this.reducedLicenseFeeType,
      Amount: "5000.00"
    },
    {
      Text: "Change Request Fee",
      EntityType: this.ServiceCenterPaymentOption,
      FeeType: this.changeRequestFeeType,
      Amount: "250.00"
    },
    {
      Text: "Change of Location Fee",
      EntityType: this.ServiceCenterPaymentOption,
      FeeType: this.changeLocationFeeType,
      Amount: "500.00"
    },
    {
      Text: "Worker Permit",
      EntityType: this.WorkerPermitPaymentOption,
      FeeType: this.applicationFeeType,
      Amount: "25.00"
    },
    {
      Text: "Manufacturer Application Fee",
      EntityType: this.ManufacturerPaymentOption,
      FeeType: this.applicationFeeType,
      Amount: "500.00"
    },
    {
      Text: "Manufacturer License Fee",
      EntityType: this.ManufacturerPaymentOption,
      FeeType: this.licenseFeeType,
      Amount: "10000.00"
    },
    {
      Text: "Manufacturer Reduced License Fee",
      EntityType: this.ManufacturerPaymentOption,
      FeeType: this.reducedLicenseFeeType,
      Amount: "5000.00"
    },
    {
      Text: "Change Request Fee",
      EntityType: this.ManufacturerPaymentOption,
      FeeType: this.changeRequestFeeType,
      Amount: "250.00"
    },
    {
      Text: "Change of Location Fee",
      EntityType: this.ManufacturerPaymentOption,
      FeeType: this.changeLocationFeeType,
      Amount: "500.00"
    },
    {
      Text: "Testing Lab Application Fee",
      EntityType: this.TestingLabPaymentOption,
      FeeType: this.applicationFeeType,
      Amount: "500.00"
    },
    {
      Text: "Testing Lab License Fee",
      EntityType: this.TestingLabPaymentOption,
      FeeType: this.licenseFeeType,
      Amount: "10,000.00"
    },
    {
      Text: "Change Request Fee",
      EntityType: this.TestingLabPaymentOption,
      FeeType: this.changeRequestFeeType,
      Amount: "250.00"
    },
    {
      Text: "Change of Location Fee",
      EntityType: this.TestingLabPaymentOption,
      FeeType: this.changeLocationFeeType,
      Amount: "500.00"
    },
    {
      Text: "Civil Penalty Fee Varies",
      EntityType: this.WorkerPermitPaymentOption,
      FeeType: this.civilPenaltyFeeType,
      Amount: ""
    },
    {
      Text: "Civil Penalty Fee Varies",
      EntityType: this.facilitatorPaymentOption,
      FeeType: this.civilPenaltyFeeType,
      Amount: ""
    },
    {
      Text: "Civil Penalty Fee Varies",
      EntityType: this.ManufacturerPaymentOption,
      FeeType: this.civilPenaltyFeeType,
      Amount: ""
    },
    {
      Text: "Civil Penalty Fee Varies",
      EntityType: this.ServiceCenterPaymentOption,
      FeeType: this.civilPenaltyFeeType,
      Amount: ""
    },
    {
      Text: "Civil Penalty Fee Varies",
      EntityType: this.TestingLabPaymentOption,
      FeeType: this.civilPenaltyFeeType,
      Amount: ""
    },
    {
      Text: "Civil Penalty Fee Varies",
      EntityType: this.trainingProgramPaymentOption,
      FeeType: this.civilPenaltyFeeType,
      Amount: ""
    },
  ]

  public paymentOptionsRenewal = [
    {
      Text: "Training Program",
      EntityType: this.trainingProgramPaymentOption,
      FeeType: this.applicationFeeType,
      Amount: "500.00",
    },
    {
      Text: "Facilitator License Fee",
      EntityType: this.facilitatorPaymentOption,
      FeeType: this.licenseFeeType,
      Amount: "2000.00"
    },
    {
      Text: "Facilitator Reduced License Fee",
      EntityType: this.facilitatorPaymentOption,
      FeeType: this.reducedLicenseFeeType,
      Amount: "1000.00"
    },
    {
      Text: "Service Center License Fee",
      EntityType: this.ServiceCenterPaymentOption,
      FeeType: this.licenseFeeType,
      Amount: "10000.00"
    },
    {
      Text: "Service Center Reduced License Fee",
      EntityType: this.ServiceCenterPaymentOption,
      FeeType: this.reducedLicenseFeeType,
      Amount: "5000.00"
    },
    {
      Text: "Change Request Fee",
      EntityType: this.ServiceCenterPaymentOption,
      FeeType: this.changeRequestFeeType,
      Amount: "250.00"
    },
    {
      Text: "Change of Location Fee",
      EntityType: this.ServiceCenterPaymentOption,
      FeeType: this.changeLocationFeeType,
      Amount: "500.00"
    },
    {
      Text: "Worker Permit",
      EntityType: this.WorkerPermitPaymentOption,
      FeeType: this.applicationFeeType,
      Amount: "25.00"
    },
    {
      Text: "Manufacturer License Fee",
      EntityType: this.ManufacturerPaymentOption,
      FeeType: this.licenseFeeType,
      Amount: "10000.00"
    },
    {
      Text: "Manufacturer Reduced License Fee",
      EntityType: this.ManufacturerPaymentOption,
      FeeType: this.reducedLicenseFeeType,
      Amount: "5000.00"
    },
    {
      Text: "Change Request Fee",
      EntityType: this.ManufacturerPaymentOption,
      FeeType: this.changeRequestFeeType,
      Amount: "250.00"
    },
    {
      Text: "Change of Location Fee",
      EntityType: this.ManufacturerPaymentOption,
      FeeType: this.changeLocationFeeType,
      Amount: "500.00"
    },
    {
      Text: "Testing Lab License Fee",
      EntityType: this.TestingLabPaymentOption,
      FeeType: this.licenseFeeType,
      Amount: "10,000.00"
    },
    {
      Text: "Change Request Fee",
      EntityType: this.TestingLabPaymentOption,
      FeeType: this.changeRequestFeeType,
      Amount: "250.00"
    },
    {
      Text: "Change of Location Fee",
      EntityType: this.TestingLabPaymentOption,
      FeeType: this.changeLocationFeeType,
      Amount: "500.00"
    },
    {
      Text: "Civil Penalty Fee Varies",
      EntityType: this.WorkerPermitPaymentOption,
      FeeType: this.civilPenaltyFeeType,
      Amount: ""
    },
    {
      Text: "Civil Penalty Fee Varies",
      EntityType: this.facilitatorPaymentOption,
      FeeType: this.civilPenaltyFeeType,
      Amount: ""
    },
    {
      Text: "Civil Penalty Fee Varies",
      EntityType: this.ManufacturerPaymentOption,
      FeeType: this.civilPenaltyFeeType,
      Amount: ""
    },
    {
      Text: "Civil Penalty Fee Varies",
      EntityType: this.ServiceCenterPaymentOption,
      FeeType: this.civilPenaltyFeeType,
      Amount: ""
    },
    {
      Text: "Civil Penalty Fee Varies",
      EntityType: this.TestingLabPaymentOption,
      FeeType: this.civilPenaltyFeeType,
      Amount: ""
    },
    {
      Text: "Civil Penalty Fee Varies",
      EntityType: this.trainingProgramPaymentOption,
      FeeType: this.civilPenaltyFeeType,
      Amount: ""
    },
  ]

  public paymentPendingStatus = 'Pending';
  public paymentClearedStatus = 'Cleared';
  public paymentAwaitingClearanceStatus = 'Awaiting Clearance';
  public paymentStatuses = [this.paymentPendingStatus,  this.paymentAwaitingClearanceStatus, this.paymentClearedStatus];
  public paymentMethods = [
    'Online',
    'Money Order',
    'Personal/Business Check',
    'Cashier\'s Check',
    'Cash'];
  private api: string = environment.privateApi + 'Payments/';

  constructor(private http: HttpClient) { }

  public getPaymentName(entityType: string, feeType: string): string {
    let option = this.paymentOptions.find(po => po.EntityType == entityType && po.FeeType == feeType);
    if(option != null && option != undefined) {
      return option.Text;
    }
    return entityType;
  }

  public getPaymentAmount(entityType: string, feeType: string): string {
    let option = this.paymentOptions.find(po => po.EntityType == entityType && po.FeeType == feeType);
    if(option != null && option != undefined) {
        return option.Amount;
    };
    return "";
  }

  public paymentOptionsByEntityType(entityType : string, renewal : boolean){
    if(!renewal)
      return this.paymentOptions.filter(po => po.EntityType == entityType);
    else
      return this.paymentOptionsRenewal.filter(po => po.EntityType == entityType);
  }


  getPaymentsByEntity(entityId: string, entityType: string): Observable<IPayment[]>{
    return this.http.get<IPayment[]>(this.api + 'GetPayments/' + entityType + '/' + entityId);
  }

  getPaymentsById(entityId: number): Observable<IPayment[]>{
    return this.http.get<IPayment[]>(this.api + 'GetPayments/' + entityId);
  }

  savePayment(payment: IPayment): Observable<IPayment>{
    return this.http.post<any>(this.api + "SavePayment", payment);
  }

  deletePayment(id: number,): Observable<any> {
    return this.http.delete<any>(this.api + 'DeletePayment/' + id);
  }

}
