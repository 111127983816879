import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AuditLogService } from "app/services/audit-log/audit-log.service";
import { SharedService } from "app/services/core/shared.service";
import { ToastrService } from "ngx-toastr";
import { IAuditLog } from "app/models/audit-logging/audit-logging";
import { AuditLoggingSearch } from "app/models/audit-logging/audit-logging-search";

@Component({
  selector: 'applicant-history-audit-log',
  templateUrl: './applicant-history-dialog.component.html',
  providers: [AuditLogService],
})

export class ApplicantHistoryDialogComponent implements OnInit  {

  public auditlogs: IAuditLog[] = [];
  public loading: boolean = false;
  public search: AuditLoggingSearch = {
    startDate: "",
    endDate: "",
    selectedUser: "",
    baseEntityId: "",
    baseEntityType: "",
    referenceId: "",
    paymentIds: [],
    applicantIds: [],
    licenseDocumentIds: [],
    noteIds: [],
    displayLicense: false,
    displayPayments: false,
    displayApplicants: false,
    displayDocuments: false,
    displayNotes: false,
    displayContactInformation: false,
    users: []
  };
  public entityId: string = "";
  public entityType: string = "";

  constructor(public sharedService: SharedService,
    private auditLogService: AuditLogService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public applicantId: string) {}

  ngOnInit(): void {
    this.loading = true;
    this.search.displayContactInformation = true;
    this.search.applicantIds.push(this.applicantId);
    this.search.baseEntityId = this.applicantId;
    this.search.baseEntityType = "Applicant"

    this.auditLogService.getAuditLogging(this.search).subscribe(
      response => {
        this.auditlogs = response;
        this.loading = false;
      },
      error => {
        console.log(error);
        this.toastr.error(error);
        this.loading = false;
      }
    );

  }
}
